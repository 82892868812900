.container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.384);
    z-index: 999999999999999;
}

.popup {
    background-color: white;
    box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 1rem;
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 100000;
    align-items: flex-start;
}

.popup div {
    margin-top: -.1rem;
}

.popup div h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    margin-bottom: .4rem;
}

.popup div p {
    font-size: .8rem;
    color: #5a5a5a;
    font-weight: 500;
    margin-bottom: 0;
    max-width: 35ch;
}