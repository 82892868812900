/**=====================
    54. base_inputs CSS start
==========================**/
.jkanban-container {
  .card-header {
    p {
      color: $theme-body-font-color;
      font-weight: 400;
    }
  }
  .card-body {
    #demo1 {
      margin-left: -15px;
    }
  }
  .btn {
    margin-bottom: 10px;
  }
  .note {
    a {
      display: contents;
    }
  }
}
.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background: $body-color;
  border-bottom: 1px solid $light-semi-gray;
  &.bg-info {
    & ~ .kanban-drag {
      background-color: rgba($info-color, 0.3) !important;
    }
  }
  &.bg-warning {
    & ~ .kanban-drag {
      background-color: rgba($warning-color, 0.3) !important;
    }
  }
  &.bg-success {
    & ~ .kanban-drag {
      background-color: rgba($success-color, 0.3) !important;
    }
  }
}
.kanban-container {
  display: contents;
}
.kanban-board {
  margin-bottom: 30px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(100% / 3 - 30px) !important;
  .kanban-drag {
    background: $body-color;
  }
}
.kanban-item {
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
  p {
    opacity: 0.7;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .list {
    display: inline-block;
    opacity: 0.4;
    li {
      display: inline-block;
      font-size: 12px;
      i {
        margin-right: 10px;
      }
    }
  }
  .kanban-box {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    display: block;
    color: inherit;
    background-color: $white;
    &:hover {
      color: inherit;
    }
    & + .kanban-box {
      margin-top: 20px;
    }
    .badge {
      font-weight: 400;
      padding: 3px 4px 4px;
    }
    h6 {
      font-size: 15px;
      margin-top: 5px;
      font-weight: 500;
    }
    .dropdown {
      display: inline-block;
      right: 10px;
      position: absolute;
      top: 20px;
      .dropdown-item {
        font-size: 13px;
        font-weight: 500;
        padding: 5px 10px;
        text-align: left;
      }
      svg {
        height: 14px;
      }
    }
    .d-flex {
      justify-content: space-between;
      align-items: center;
    }
    .dropdown-toggle {
      &:after {
        content: none;
      }
    }
    .customers {
      li {
        &:first-child {
          vertical-align: middle;
        }
      }
    }
    .customers {
      ul {
        li {
          + li {
            margin-left: -10px;
          }
        }
      }
    }
    .date {
      font-size: 11px;
      opacity: 0.5;
    }
  }
}
footer {
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1300px) {
  .kanban-board {
    width: calc(100% / 2 - 30px) !important;
  }
}
@media only screen and (max-width: 700px) {
  .kanban-container .kanban-board {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .jkanban-container {
    .btn {
      margin-left: unset;
      padding: 10px 15px;
    }
  }
}
@media (max-width: 600px) {
  .jkanban-container{
    .card-body{
      padding: 12px !important;
    }
  }
  .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
    padding: 10px 15px;
  }
  .kanban-container .kanban-board {
    width: 320px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 480px) {
  .jkanban-container{
    .kanban-container{
      .kanban-drag{
        > div{
          > div{
            .react-kanban-column{
              > div{
                .react-kanban-column-header{
                  span{
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
    padding: 10px 15px;
  }
  .kanban-container .kanban-board {
    width: 270px !important;
  }
  .kanban-board {
    .kanban-drag {
      padding: 10px;
    }
    header {
      padding: 10px 10px 0;
      .kanban-title-board {
        font-size: 14px;
      }
    }
  }
  .jkanban-container {
    .card {
      .card-body {
        padding: 15px;
      }
    }
    .btn {
      padding: 7px 12px;
    }
  }
  .kanban-item {
    margin-bottom: 10px;
    .kanban-box {
      padding: 15px;
    }
  }
  .colorfull-kanban {
    .kanban-board-header {
      padding-bottom: 10px;
    }
  }
}
/**=====================
    54. base_inputs CSS Ends
==========================**/