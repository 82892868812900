.vertical-timeline {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;
    height: fit-content;
    width: 100%;
}

.vertical-timeline::before {
    background: rgb(0, 0, 0);
    left: 10px;
}

.vertical-timeline-element--work {
    margin: 0;
    width: 100%;
    display: flex;
}