.modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal_dialog {
    aspect-ratio: 1076/670;
    max-width: 700px;
    width: 90vw;
    background: url(../files/Popup.png);
    background-size: cover;
    background-position: center;
    display: flex;
    padding: 3vw;
    border-radius: 30px;
    box-shadow: 8.03075px 4.589px 22.94501px 0px rgba(89, 38, 240, 0.22);
}

.modal_dialog>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content h4 {
    color: #FFF;
    font-size: min(2vw, 30px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.8px;
    max-width: 300px;
    margin-top: 20px;
}

.btn_con {
    display: flex;
    gap: 1rem;
}

.btn_con button {
    background: none;
    border: none;
    outline: none;
    border-radius: 10px;
    color: #FFF;
    font-size: min(1vw, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;

}

@media screen and (max-width: 700px) {
    .modal_dialog {
        flex-direction: column;
        background-position: 0px;
    }

    .extra {
        display: none;
    }

    .content {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        text-align: center !important;
        margin-bottom: 2rem !important;
        margin-top: 40vh !important;
    }

    .content h4 {
        font-size: 26px;
    }

    .con_text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btn_con {
        width: 100%;
        margin-top: 1rem;
    }

    .btn_con button {
        width: 100%;
        font-size: 1rem !important;
        border-radius: 10px;
    }
}