.main {
    position: relative;
    width: 100%;
}

.placeholder {
    position: absolute;
    top: 17px;
    /* transform: translateY(-50%); */
    left: 60px;
    color: #c4c4c4;
    font-weight: 600;
    pointer-events: none;
}

.input_container {
    border: 1px solid #cccccc70;
    padding: 14px 14px 14px 60px;
    width: 100%;
    font-size: 16px;
    cursor: text;
    min-height: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 3px #3333333e;
    background-color: white;
}

.input_container:focus {
    outline: none;
    border-color: #007BFF;
}

.input_container:focus~.placeholder {
    display: none;
}

.card_img {
    position: absolute;
    top: 17px;
    /* transform: translateY(-50%); */
    height: 20px;
    object-fit: contain;
    left: 13px;
}

.error {
    font-size: .7rem;
    font-weight: 500;
    color: red;
    padding: .4rem .4rem 0;
}