.container {
    display: flex;
    gap: 40px;
    position: relative;
    width: 100%;
    min-height: 80vh;
    max-height: 650px;
    padding: 24px;
}

.left {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
}

.top {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 320px;
}

.top h1 {
    color: #5926F0;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5.76px;
    text-transform: uppercase;
}

.top h2 {
    color: #000;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
}

.top p {
    color: #8D8D8D;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 157%;
    letter-spacing: -0.18px;
}

.top ol {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 20px;
}

.top ol li {
    color: #8D8D8D;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 157%;
    letter-spacing: -0.18px;
    list-style-type: none;
}

.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
}

.bottom .button {
    border-radius: 8px;
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(277deg, #E64067 -9.52%, #5926F0 62.22%, #4828A0 119.38%);
    background-blend-mode: overlay, normal;
    box-shadow: 0px 4px 5px rgba(89, 38, 240, 0.04);
    color: #FFF;
    font-family: Manrope;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px 24px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.bottom .button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 8px rgba(89, 38, 240, 0.08);
}

.right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right_item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 600px;
}

/* Last step styles */
.last {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
}

.last h1 {
    color: #5926F0;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5.76px;
    text-transform: uppercase;
}

.last > h2 {
    border-radius: 6px;
    background: #FAFAFA;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #FF1F1F;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.48px;
    width: fit-content;
}

.avoid {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background: #EFEAFE;
}

.avoid h2 {
    color: #5926F0;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 180%;
    letter-spacing: -0.36px;
}

.avoid ul {
    padding-left: 20px;
    margin: 0;
}

.avoid ul li {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
    letter-spacing: -0.18px;
    list-style-type: disc;
}

.note {
    background: #FFF493;
    padding: 16px;
    border-radius: 8px;
}

.note p {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 157%;
    letter-spacing: -0.36px;
    margin: 0;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 32px;
}

.t_button {
    background: transparent;
    border: none;
    outline: none;
    color: #8D8D8D;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 16px;
    transition: color 0.3s ease;
}

.t_button:hover {
    color: #5926F0;
}

.f_button {
    border-radius: 6px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(277deg, #E64067 -9.52%, #5926F0 62.22%, #4828A0 119.38%);
    background-blend-mode: overlay, normal;
    box-shadow: 0px 4px 5px rgba(89, 38, 240, 0.04);
    border: none;
    outline: none;
    padding: 12px 24px;
    color: #FFF;
    font-family: Manrope;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 24px;
}

.f_button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 8px rgba(89, 38, 240, 0.08);
}

@media screen and (max-width: 1000px) {
    .container {
        min-height: unset;
        max-height: unset;
        flex-direction: column-reverse;
        padding: 0;
        gap: 0;
        background: #FAFAFA;
        border-radius: 20px;
        overflow: hidden;
    }

    .left {
        padding: 24px;
        width: 100%;
        gap: 32px;
    }

    .top {
        max-width: none;
        gap: 16px;
    }

    .top h1 {
        font-size: 14px;
    }

    .top h2 {
        font-size: 24px;
    }

    .top p, .top ol li {
        font-size: 16px;
    }

    .bottom {
        flex-direction: column-reverse;
        gap: 32px;
        margin-top: 16px;
    }

    .bottom .button {
        width: 100%;
        justify-content: center;
        padding: 16px;
    }

    .right_item img {
        max-height: 300px;
    }

    .last {
        padding: 16px;
        gap: 24px;
    }

    .buttons {
        flex-direction: column;
        width: 100%;
    }

    .t_button, .f_button {
        width: 100%;
        justify-content: center;
    }
}