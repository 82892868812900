.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    font-family: 'Manrope', sans-serif;
}

.error-box {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error-box h1 {
    font-size: 2.5rem;
    color: #ff6b6b;
}

.error-box p {
    font-size: 1.2rem;
    margin: 15px 0;
    color: #333;
}

.error-button {
    background-color: #ff6b6b;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.error-button:hover {
    background-color: #ff4b4b;
}