/**============================
    27. Touchspin  CSS Start
==============================**/
.bootstrap-touchspin {
  input {
    &.touchspin {
      padding: 0 12px;
    }
    &.left-radius {
      border-bottom-left-radius: 10px !important;
      border-top-left-radius: 10px !important;
    }
  }
  .input-group .btn {
    padding: 0.5rem 1.75rem;
  }
  .touchspin-vertical-tab {
    .input-group {
      .input-group-btn-vertical {
        position: relative;
        white-space: nowrap;
        width: 35px;
        vertical-align: middle;
        display: table-cell;
        .bootstrap-touchspin-down {
          margin-top: -2px;
        }
        i {
          position: absolute;
          top: 4px;
          left: 10px;
          font-size: 15px;
          font-weight: normal;
        }
        > .btn {
          display: block;
          float: none;
          width: 100%;
          max-width: 100%;
          padding: 11px;
          margin-left: -1px;
          position: relative;
        }
      }
    }
  }
  .input-group {
    font-family: $font-Montserrat;
    border-radius: 10px;
    .btn {
      border-radius: 0 !important;
    }
  }
  .dropdown-basic {
    .dropdown {
      .dropbtn {
        padding: 9px 30px;
      }
      .dropdown-content {
        z-index: 9999;
      }
    }
  }
}
// custom css
.touchspin-sec {
  .bootstrap-touchspin {
    .bootstrap-touchspin-prefix,
    .bootstrap-touchspin-postfix {
      border-radius: unset !important;
    }
    input {
      border-radius: unset !important;
    }
  }
}
/**=====================
    27. Touchspin  CSS Ends
==========================**/