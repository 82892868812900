@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "react-toastify/dist/ReactToastify.css";
@import '~react-dropzone-uploader/dist/styles.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-image-lightbox/style.css";
@import '@asseinfo/react-kanban/dist/styles.css';
@import "~react-vertical-timeline-component/style.min.css";
@import "~react-image-crop/dist/ReactCrop.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "vendors/scrollable.scss";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/font-awesome.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import 'simplebar-react/dist/simplebar.min.css';
@import "vendors/sticky/sticky";
@import "vendors/todo";
@import "style.scss";
@import "responsive.scss";


.modal-dialog {
    min-height: 100vh;
    display: flex;
    align-items: center;
}