.sm_cir {
    height: 5px;
    width: 5px;
    background-color: #CFCFCF;
    border-radius: 100%;
    z-index: 2;
}

.dotted {
    width: 100%;
    border-bottom: 2px dashed #CFCFCF;
    position: absolute;
    z-index: 1;
}

.big_cir {
    height: 16px;
    width: 16px;
    background-color: #CFCFCF;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    z-index: 2;
}