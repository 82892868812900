.container {}

.container>h5 {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
    margin-bottom: 1.5rem;
}

.cards {
    background: #F4F4F4;
    padding: 1rem;
    padding-right: 0;
    overflow-x: auto;
    display: flex;
    gap: 1rem;
    max-width: 32vw;
    /* width: 100%; */
}

.card {
    aspect-ratio: 250/150;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    border-radius: 9px;
}

.card:nth-child(3n+1) {
    background: url(../../../AddFunds/files/pb_card.png);
    background-position: center;
    background-size: 200%;
    background-repeat: no-repeat;
}

.card:nth-child(3n+2) {
    background: url(../../../AddFunds/files/gy_card.png);
    background-position: center;
    background-size: 200%;
    background-repeat: no-repeat;
}

.card:nth-child(3n+3) {
    background: url(../../../AddFunds/files/rb_card.png);
    background-position: center;
    background-size: 200%;
    background-repeat: no-repeat;
}

.card:last-child {
    margin-right: 1rem;
}

.c_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c_top img {
    width: 14.127px;
    height: 21.119px;
    flex-shrink: 0;
    object-fit: contain;
}

.c_bot {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.c_bot span {
    color: rgba(255, 255, 255, 0.91);
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
}

.card_details {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 1.3rem;
    margin-top: 2rem;
}

.card_details>h5 {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1000px) {
    .cards {
        max-width: 90vw;
    }
}