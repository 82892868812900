.benef {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 0 0 10px #e9e9e9;
}

.benef_top {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.dp {
    background: linear-gradient(180deg, #5926F0 0%, #33168A 100%);
    height: 200px;
    width: 200px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    border-radius: 100px;
    color: white;
    font-size: 5rem;
    font-weight: 900;
}

.bt_dets {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.benef textarea {
    padding: 1rem;
    height: 100px;
    border-radius: .8rem;
    resize: none;
    border: 1px solid #e9e9e9;
    outline: none;
}

@media screen and (max-width: 1000px) {
    .benef_top {
        align-items: flex-start;
    }

    .dp {
        height: 50px;
        width: 50px;
        font-size: 2rem;
    }
}