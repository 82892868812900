/**=====================
    58. Authentication CSS Start
==========================**/
.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  // background: url("../../images/login/login_bg.jpg");
  padding: 30px 12px;

  .login-main1 {
    width: 450px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: $white;

    .theme-form {
      h4 {
        margin-bottom: 5px;
      }

      label {
        font-size: 15px;
        letter-spacing: 0.4px;
      }

      .checkbox {
        label {
          &::before {
            background-color: $card-body-color;
            border: 1px solid $light-gray;
          }
        }
      }

      input {
        transition: all 0.3s ease;

        &::-webkit-input-placeholder {
          color: $light-text;
        }
      }

      p {
        margin-bottom: 25px;
        font-size: 14px;
        color: $dark-gray;
      }

      .login-btn {
        margin-bottom: 10px;
        position: relative;
      }

      .link {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }

    &.login-tab1 {
      position: relative;
      border-top-left-radius: 0;
      height: 100%;

      .nav-tabs {
        border-bottom: none;
        position: absolute;
        background-color: $white;
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
        left: -70px;
        top: 0;
        margin-bottom: 0;

        .nav-item {
          .nav-link {
            position: relative;

            img {
              filter: grayscale(100%);
              width: 20px;
              height: 20px;
              transition: 0.5s;
            }

            &:after {
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              border-color: transparent;
              border-style: solid;
              top: 12px;
              border-width: 5px 0 5px 5px;
              border-left-color: lighten($primary-color, 25%);
              opacity: 0;
              transform: scale(0);
              color: #fff;
              left: 14px;
              z-index: 2;
              transition: all 0.3s ease;
            }

            span {
              position: absolute;
              right: 0;
              width: max-content;
              text-align: right;
              padding: 7px 10px;
              display: block;
              top: 0;
              border-radius: 5px;
              transform: scale(0);
              transition: all 0.3s ease;
              opacity: 0;
              color: var(--theme-default);
              background-color: lighten($primary-color, 25%);
              box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
            }

            &:hover {
              &:after {
                opacity: 1;
                transform: scale(1);
                transition: all 0.3s ease;
              }

              span {
                opacity: 1;
                right: 56px;
                transform: scale(1);
                transition: all 0.3s ease;
              }
            }

            &.active {
              img {
                filter: initial;
                transition: 0.5s;
              }
            }
          }
        }
      }

      .border-tab {
        border-radius: 0;

        &.nav-tabs {
          .nav-item {
            .nav-link {
              border-bottom: none;
              border-right: 2px solid transparent;
              border-top-right-radius: 0;

              &.active,
              &.show,
              &:focus {
                border-right: 2px solid var(--theme-default);
                border-bottom: none;
              }
            }
          }
        }
      }

      .auth-content {
        text-align: center;
      }
    }
  }

  .logo {
    display: block;
    margin-bottom: 16px;
    text-align: center;
  }

  .btn-showcase {
    .btn {
      line-height: 1;
      padding: 10px 15px;
      margin: 0;

      &+.btn {
        margin-left: 5px;
      }

      svg {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }

  .login-main {
    width: 450px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: $white;

    .theme-form {
      h4 {
        margin-bottom: 5px;
      }

      .unlock-1 {
        width: 75%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      label {
        font-size: 15px;
        letter-spacing: 0.4px;
      }

      .checkbox {
        label {
          &::before {
            background-color: $card-body-color;
            border: 1px solid $light-gray;
          }
        }
      }

      .login-social-title {
        position: relative;
        z-index: 1;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;

        h6 {
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          color: $light-text;
          background-color: $white;
          padding-left: 25px;
          padding-right: 25px;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $light-gray;
          top: 10px;
          z-index: -1;
          right: 0;
        }
      }

      input {
        transition: all 0.3s ease;

        &::-webkit-input-placeholder {
          color: $light-text;
        }

        &:hover,
        &:focus {
          box-shadow: none !important;
          transition: all 0.3s ease;
        }
      }

      p {
        margin-bottom: 25px;
        font-size: 14px;
        color: $dark-gray;
      }

      .form-group {
        margin-bottom: 10px;
        position: relative;
      }

      .link {
        position: absolute;
        top: 10px;
        right: 0;
      }

      ul.login-social {
        display: flex !important;
        align-items: center;
        justify-content: center;

        li {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid $light-gray;
          background-color: rgba($primary-color, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: auto;
            height: 16px;
            color: $primary-color;
            vertical-align: middle;
          }

          &:nth-child(n + 2) {
            margin-left: 15px;
          }

          &:hover {
            background-color: $primary-color;

            svg {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.pass-hide {
  .show-hide {
    top: 53px;
  }
}

.show-hide {
  position: absolute;
  top: 58px;
  right: 20px;
  transform: translateY(-50%);

  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-default);

    &.show {
      &:before {
        content: "show";
      }
    }

    &:before {
      content: "hide";
    }
  }
}

.needs-validation {
  .invalid-feedback {
    color: $dark-gray;
  }

  .show-hide {
    right: 30px;
  }

  .invalid-tooltip {
    right: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .login-card {
    &.auth-login {
      margin: 0;

      .login-main {
        &.login-tab1 {
          .nav-tabs {
            display: flex;
            flex-direction: row !important;
            left: 0;
            top: -42px;

            &.border-tab {
              .nav-item {
                .nav-link {
                  border-right: unset;
                  border-bottom: 2px solid transparent;
                  border-radius: 0;

                  &.active {
                    border-right: unset;
                    border-bottom: 2px solid $primary-color;
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }

      .logo {
        margin-bottom: 60px;
      }
    }

    .login-main1 {
      .theme-form {
        h4 {
          font-size: 20px;
        }

        .link {
          position: unset;
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .login-card {
    .login-main1 {
      &.login-tab1 {
        .nav-tabs {
          .nav-item {
            .nav-link {
              &:hover {
                span {
                  display: none;
                }

                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**=====================
    58. Authentication CSS Ends
==========================**/