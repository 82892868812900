.container {
    padding: 2rem;
    background-color: white;
    display: flex;
    gap: 1.5rem;
}

.left {
    flex: 1;
    min-width: 350px;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.amount {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.04);
    padding: 1.25rem;
}

.amount .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.amount .top span {
    color: #848484;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: .9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
}

.badge {
    border-radius: 233.348px;
    background: rgba(89, 38, 240, 0.55);
    padding: .3rem .55rem;
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: .45rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.amount h5 {
    margin-bottom: 0;
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -2.4px;
}

.er_item {
    padding: 1rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #F4F4F4;
}

.er_item span {
    color: #909090;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: .9rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.16px;
}

.er_item p {
    margin-bottom: 0;
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

.er_item input {
    outline: none;
    border: none;
    border-radius: 6px;
    width: 100%;
    flex: 1;
    padding: .7rem 1rem;
}

.right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 4;
    min-width: 350px;
}

.row {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.row_left {
    min-width: 350px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.r_item {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.04);
    align-items: center;
}

.r_item span {
    min-width: 80px;
    color: rgba(0, 0, 0, 0.30);
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

.recipient {
    display: flex;
    gap: .8rem;
}

.dp {
    width: 44.266px;
    height: 45.704px;
    border-radius: 8px;
    box-shadow: 0px 2.602px 20.813px 0px rgba(109, 41, 220, 0.17);
    background: linear-gradient(-24deg, rgb(210, 60, 122) 0%, rgb(164, 52, 167) 43.66%, rgb(88, 38, 239) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    container-type: inline-size;
    font-size: 1.2rem;
    font-weight: 800;
    color: white;
}

.r_details {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.r_details h5 {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.364px;
    max-width: 30ch;
    margin-bottom: 0;
}

.r_details p {
    margin-bottom: 0;
    color: #848484;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: .6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
    /* 20.4px */
}

.country {
    display: flex;
    align-items: center;
    gap: .7rem;
}

.country img {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

.country span,
.p_type {
    color: var(--colour-of-country-names, #000);
    font-family: Manrope;
    font-size: .9rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}

.row_right {
    min-width: 350px;
    flex: 1;
}

.note {
    color: #6C6C6C;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.map {
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    box-shadow: 0px 2px 9.7px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.map .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: .4rem;
}

.map .top span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.map .top img {
    width: 15.07px;
    height: 15.07px;
    object-fit: contain;
}

.address {
    display: flex;
    gap: .5rem;
}

.address img {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    object-fit: contain;
}

.address span {
    color: rgba(0, 0, 0, 0.30);
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

.map_img {
    height: 190px;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
}

.files {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    box-shadow: 0px 2px 9.7px 0px rgba(0, 0, 0, 0.10);
    padding: 1rem;
    align-items: center;
    display: flex;
    gap: 1rem;
}

.files span {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.files_count {
    border-radius: 4px;
    background: #FFF2CC;
    padding: .3rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: .45rem;
}

.files_count img {
    width: 9px;
    height: 9px;
    object-fit: contain;
}

.files_count span {
    color: #D3A515;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: .6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.open_btn {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .container {
        flex-direction: column;
        padding: 0;
        margin-top: 1.5rem;
    }

    .r_item {
        flex-direction: column;
        align-items: flex-start;
    }

    .status {
        background-color: transparent;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {

    .left,
    .row_right,
    .row_left,
    .right {
        min-width: 280px;
    }
}