/**=====================
    63. Progress CSS Start
==========================**/
.progress-showcase {
  .progress {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sm-progress-bar {
  height: 5px;
}

.lg-progress-bar {
  height: 20px;
}

// progress bar gradient loop css
.progress-animate {
  overflow: inherit;
  background-color: $transparent-color;
  margin-bottom: 2px;

  span {
    position: absolute;
    bottom: 25px;
    right: -10px;
    font-weight: 600;
    font-size: 14px;
  }
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@each $progress-gradient-name,
$progress-gradient-color in (primary, var(--theme-default)),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .progress-gradient-#{$progress-gradient-name} {
    background-image: linear-gradient(to right,
        $transparent-color,
        $progress-gradient-color );
    position: relative;

    &:hover {
      background-size: 100%;
    }

    &::after {
      position: absolute;
      content: "";
      top: -3px;
      right: -1px;
      background-color: $progress-gradient-color;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      box-shadow: 0 0 17px 4px rgba($progress-gradient-color, 0.36);
    }

    .animate-circle {
      position: absolute;
      top: -11px;
      right: -8px;
      height: 25px;
      width: 25px;
      border: 5px solid $progress-gradient-color;
      border-radius: 70px;
      animation: heartbit 1s ease-out;
      animation-iteration-count: infinite;
    }
  }
}

/**=====================
     63. Progress CSS Ends
==========================**/