/**=====================
    18. Print CSS Start
==========================**/
@media print {
  .page-wrapper {
    .page-title {
      visibility: hidden;
    }
    .page-body-wrapper {
      .page-title {
        display: none;
      }
    }
  }
  table.fixedHeader-floating {
    display: none;
  }
}
.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px;
}
.invoice {
  .text-end {
    input {
      margin-top: 6px;
      width: inherit;
    }
  }
  .d-flex {
    align-items: center;
  }
}
/**=====================
    18. Print CSS Ends
==========================**/