 @import './assets/scss/app.scss';


 .wrong input {
     border: 1px solid red !important;
 }

 .correct input {
     border: 1px solid green !important;
 }

 @keyframes horizontal-shaking {
     0% {
         transform: translateX(0);
     }

     25% {
         transform: translateX(5px);
     }

     50% {
         transform: translateX(-5px);
     }

     75% {
         transform: translateX(5px);
     }

     100% {
         transform: translateX(0);
     }
 }