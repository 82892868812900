.active {
    min-width: 220px;
}

.image {
    width: 30px;
    height: 30px;
}

.head {
    font-size: 18px;
}

@media screen and (max-width: 1700px) {
    .active {
        min-width: 150px;
    }

    .image {
        width: 20cqi;
        height: 20cqi;
    }

    .head {
        font-size: 9cqi;
    }
}