.bottom_nav {
    position: fixed;
    bottom: 0px;
    left: 0;
    height: 80px;
    width: 100%;
    background: #FAFAFA;
    z-index: 999;
}

.bn_list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    align-items: center;
    padding: 0 .5rem;
}

.bn_list img {
    object-fit: contain;
}

.bnl_item button,
.bnl_item button:hover,
.bnl_item button:active {
    background-color: #fdfdfd;
    /* border: 6px solid #ededed; */
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .75rem;
    scale: 1.6;
    transform-origin: bottom center;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    padding: .7rem;
}

.bnl_item button {
    animation: 2s boxShadowAnim linear alternate-reverse infinite;
}

.bnl_item button img {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
}

.bnli_a a {
    display: flex;
    flex-direction: column;
    gap: 1px;
    align-items: center;
}

.bnli_a div {
    height: 5px;
    width: 5px;
    border-radius: 7px;
    background-color: #5926f0;
}

.fixed {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #11111121;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 2rem 10rem;
    top: 0;
}

.fixed a {
    width: 100%;
}

.f_btn {
    padding: .8rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-radius: .6rem;
    background: #5926F0;
    width: 100%;
}

.f_btn img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    object-fit: contain;
}

.f_btn span {
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.f_btn.f_btn_w {
    background-color: white;
}

.f_btn.f_btn_w span {
    color: black;
}

@keyframes boxShadowAnim {
    from {
        box-shadow: 0 0 2px 0px #5926f0;
    }

    to {
        box-shadow: 0 0 12px 5px #5926f0;
    }
}