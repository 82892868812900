/**=====================
    08. breadcrumb CSS Start
==========================**/
.breadcrumb-colored {
  padding: 12px 16px;
  border-radius: 4px;
  li {
    a {
      color: $breadcrumb-ancher-color;
    }
    &.active {
      color: $breadcrumb-ancher-color;
      opacity: 0.7;
    }
  }
  .breadcrumb-item {
    &:before {
      color: $breadcrumb-ancher-color;
    }
  }
  .breadcrumb-item.txt-dark {
    &:before {
      color: $breadcrumb-ancher-dark-color;
    }
  }
}
.breadcrumb-icon {
  --bs-breadcrumb-divider: ">";
  li {
    display: inline-block;
  }
}
.breadcrumb-no-divider {
  --bs-breadcrumb-divider: "";
}
.breadcrumb-item {
  &.active {
    text-transform: capitalize;
  }
}
@each $bg-name, $bg-color in (primary, $primary-color),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (warning, $warning-color)
  {
    .bg-outline-#{$bg-name} {
      border: 1px solid $bg-color;
      color: $bg-color;
      background-color: transparent;
      li.breadcrumb-item{
        &.active{
          color: $bg-color;
        }
        &:before{
          color: $bg-color;
        }
        a{
          color: $bg-color;
          &.active{
            color: $bg-color;
          }
        }
      }
    }
  }
/**=====================
    08. breadcrumb CSS ends
==========================**/